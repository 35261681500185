"use client"

import { FC, FormEventHandler, useCallback, useState } from "react"
import { useRouter } from "next/navigation"
import { signIn } from "next-auth/react"
import Link from "next/link"

import FormField from "@/components/FormField"
import Button from "@/components/Button"
import useToast from "@/hooks/useToast"
import { captureException } from "@sentry/nextjs"

export const LoginForm: FC = () => {
  const router = useRouter()
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const { addToast } = useToast()
  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = useCallback<FormEventHandler>(
    async (e) => {
      e.preventDefault()
      setIsSubmitting(true)
      const response = await signIn("credentials", {
        redirect: false,
        email,
        password,
      })
      if (response?.error) {
        setPassword("")
        addToast(response?.error, "error")
        captureException(response.error)
      } else {
        router.push("/map")
      }
      setIsSubmitting(false)
    },
    [addToast, email, password, router]
  )

  return (
    <form
      method="post"
      action="/api/auth/callback/credentials"
      onSubmit={onSubmit}
    >
      <FormField
        className="mb-6"
        label="Email"
        type="email"
        required
        value={email}
        autoFocus
        onChange={(e) => setEmail(e.target.value)}
      />
      <FormField
        className="mb-6"
        label="Password"
        type="password"
        required
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />

      <Link href="/password/forgot" className="mb-6 block underline">
        Forgot Password
      </Link>

      <Button
        as="button"
        type="submit"
        theme="black"
        className="w-full"
        disabled={isSubmitting}
      >
        {isSubmitting ? "Loading..." : "Log In"}
      </Button>
    </form>
  )
}

export default LoginForm
