"use client"

import {
  FC,
  InputHTMLAttributes,
  useCallback,
  useId,
  useMemo,
  useRef,
} from "react"
import clsx from "clsx"
import { Camera } from "iconoir-react"
import Image from "@/components/Image"
import Button from "../Button"

export const ImageField: FC<
  InputHTMLAttributes<HTMLInputElement> & {
    label?: string
    hasError?: boolean | number[]
  }
> = ({ className, label, value, children, hasError: _hasError, ...props }) => {
  const hash = useId()
  const id = props.id || ["input", hash].join("_")
  const inputRef = useRef<HTMLInputElement>(null)

  const previewSrc = useMemo(() => {
    if (!value) {
      return null
    }
    if (typeof value === "string") {
      return value
    }
    if (value instanceof File) {
      return URL.createObjectURL(value)
    }
    return null
  }, [value])

  const fireOnChange = useCallback(() => {
    const ev = new Event("change", { bubbles: true, cancelable: true })
    inputRef.current!.dispatchEvent(ev)
  }, [])

  return (
    <div className={clsx(className, "flex items-center gap-4")}>
      <label htmlFor={id} id={[id, "label"].join("_")} className="sr-only">
        {label}
      </label>
      <input
        type={props.type || "file"}
        className="peer sr-only"
        {...props}
        id={id}
        ref={inputRef}
      />
      <button
        type="button"
        style={{ width: 70, height: 70 }}
        className="relative block items-center justify-center rounded-lg border border-grey-500 p-5 peer-focus:outline peer-focus:outline-grey-900"
        onClick={() => inputRef.current?.click()}
        aria-controls={id}
        aria-labelledby={[id, "label"].join("_")}
        tabIndex={-1}
      >
        {previewSrc && (
          <Image fill src={previewSrc} unoptimized alt="" sizes="70px" />
        )}
        <Camera width="24" height="24" />
        {children}
      </button>
      <Button
        as="button"
        type="button"
        theme="grey"
        size="small"
        onClick={() => {
          inputRef.current!.value = ""
          fireOnChange()
        }}
      >
        Remove Image
      </Button>
    </div>
  )
}
export default ImageField
