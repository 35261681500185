import clsx from "clsx"
import { FC } from "react"
import { NavArrowDown } from "iconoir-react"
import { inputThemes } from "./styles"
import {
  HTMLInputProps,
  HTMLSelectProps,
  HTMLTextareaProps,
  InputElProps,
} from "./types"

const InputEl: FC<InputElProps> = ({
  theme,
  type = "text",
  className,
  options,
  ...props
}) => {
  if (type === "textarea") {
    return (
      <textarea
        className={clsx(
          theme && inputThemes[theme],
          "block min-h-[8rem] w-full p-3",
          className
        )}
        {...(props as HTMLTextareaProps)}
      />
    )
  }

  if (type === "select") {
    return (
      <>
        <select
          className={clsx(
            theme && inputThemes[theme],
            "block w-full appearance-none p-3",
            className
          )}
          {...(props as HTMLSelectProps)}
        >
          {options &&
            options.map((option) => (
              <option
                key={Object.keys(option).join("")}
                value={Object.keys(option)}
              >
                {Object.values(option)}
              </option>
            ))}
        </select>
        <NavArrowDown
          width="16"
          height="16"
          className="absolute bottom-4 right-2"
        />
      </>
    )
  }
  return (
    <input
      className={clsx(
        "rounded-[1px] p-3",
        type !== "checkbox" &&
          type !== "radio" &&
          [theme && inputThemes[theme], "block w-full"].join(" "),
        className
      )}
      type={type}
      {...(props as HTMLInputProps)}
    />
  )
}

export default InputEl
