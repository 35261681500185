import { API } from "@/utils/api"

export type UploadMedia = {
  type: "image" | "video" | "audio"
  url: string
  file: File
  alt_text?: string
}

export type MediaResponse = {
  id: number
  file: string
  presigned_url: string
  media_type: string
  created_on: string
  modified_on: string
}

export type UploadMediaResponse = Promise<MediaResponse>

export const uploadMedia = ({
  type,
  url,
  file,
  alt_text = "",
}: UploadMedia): UploadMediaResponse => {
  const formData = new FormData()

  formData.append("media_type", type)
  formData.append("file", file)
  formData.append("alt_text", alt_text)

  return API.post(url, formData).then(({ response, data }) => {
    if (!response.ok) {
      throw Error(response.statusText)
    }

    if (!data?.id) {
      throw Error("Error uploading file")
    }

    return data as MediaResponse
  })
}
