import { FC, useId } from "react"
import clsx from "clsx"
import { RichTextEditor } from "@/components/RichTextEditor"
import { Caption } from "@/text"
import { FormFieldProps } from "./types"
import { InputWrapper, Labels } from "./styles"
import InputEl from "./InputEl"
import RadioCheckboxEl from "./RadioCheckboxEl"
import ImageField from "./Image"

export const FormField: FC<FormFieldProps> = ({
  theme = "light",
  label,
  htmlLabel,
  type,
  validationText,
  hasError: _hasError = false,
  helperText,
  className,
  onChange,
  validators: _validators,
  defaultValue,
  defaultCheckboxValue: _defaultCheckboxValue,
  mediaEndpoint,
  children,
  ...props
}) => {
  const hash = useId()
  const id = props.id || ["input", hash].join("_")

  const hasError = Array.isArray(_hasError) ? _hasError.length > 0 : _hasError

  const Label = Labels[theme]

  if (type === "hidden") {
    return (
      <InputEl type={type} id={id} defaultValue={defaultValue} {...props} />
    )
  }

  if (type === "radio" || type === "checkbox") {
    return (
      <RadioCheckboxEl
        type={type}
        id={id}
        label={label as string}
        htmlLabel={htmlLabel}
        className={className}
        onChange={onChange}
        theme={theme}
        defaultValue={defaultValue}
        {...props}
      />
    )
  }

  if (type === "rte") {
    return (
      <InputWrapper className={className}>
        <Label htmlFor={id}>{htmlLabel || label}</Label>

        <RichTextEditor
          onContentChange={onChange}
          name={props.name}
          defaultValue={defaultValue as string}
          mediaEndpoint={mediaEndpoint}
        />
        {helperText && <Caption>{helperText}</Caption>}
        {hasError && validationText && (
          <Label as="p" className="mt-2 text-red-700">
            {validationText}
          </Label>
        )}
        {children}
      </InputWrapper>
    )
  }

  if (type === "image") {
    return (
      <InputWrapper className={className}>
        <ImageField label={label} onChange={onChange} id={id} {...props} />
        {helperText && <Caption>{helperText}</Caption>}
        {hasError && validationText && (
          <Label as="p" className="mt-2 text-red-700">
            {validationText}
          </Label>
        )}
        {children}
      </InputWrapper>
    )
  }

  return (
    <InputWrapper className={className}>
      {label && <Label htmlFor={id}>{htmlLabel || label}</Label>}

      <InputEl
        id={id}
        type={type ?? "text"}
        onChange={onChange}
        theme={theme}
        defaultValue={defaultValue}
        className={clsx(hasError && "border-red-700")}
        {...props}
      />
      {helperText && <Caption>{helperText}</Caption>}
      {hasError && validationText && (
        <Label as="p" className="mt-2 text-[1rem] text-red-700">
          {Array.isArray(_hasError) && Array.isArray(validationText)
            ? validationText
                .filter((_, idx) => _hasError.includes(idx))
                .join("; ")
            : validationText}
        </Label>
      )}
      {children}
    </InputWrapper>
  )
}
export default FormField
