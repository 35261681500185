"use client"

import { FC, useRef } from "react"
import dynamic from "next/dynamic"
import { captureException } from "@sentry/nextjs"

import { uploadMedia } from "@/utils/upload"
import "@/styles/mdx.css"

import SunEditorCore from "suneditor/src/lib/core"
import "suneditor/dist/css/suneditor.min.css"

import {
  UploadBeforeHandler,
  UploadBeforeReturn,
} from "suneditor-react/dist/types/upload"

import { RichTextEditorProps } from "./types"

const SunEditor = dynamic(() => import("suneditor-react"), {
  ssr: false,
})

export const RichTextEditor: FC<RichTextEditorProps> = ({
  onContentChange,
  defaultValue,
  mediaEndpoint,
  ...props
}) => {
  const editor = useRef<SunEditorCore>()

  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor
  }

  return (
    <div className="w-full overflow-x-hidden">
      <SunEditor
        defaultValue={defaultValue}
        autoFocus={false}
        getSunEditorInstance={getSunEditorInstance}
        setOptions={{
          // Core
          iframeCSSFileName: "",
          className: `mdx`,
          stickyToolbar: -1,
          tabDisable: true,

          // Sizing
          width: "100%",
          minHeight: "40vh",

          // Toolbar
          buttonList: [
            ["undo", "redo"],
            ["bold", "italic"],
            ["list", "blockquote", "link"],
            ["image", "video"],
            ["removeFormat"],
          ],

          // Images
          imageHeightShow: false,
          imageUploadSizeLimit: 20971520, // 20mb limit
          imageAccept: ".jpg, .jpeg, .png, .webp",

          // Videos
          videoResizing: false,
          videoHeightShow: false,
          videoAlignShow: false,
          videoRatioShow: false,
          videoRotation: false,

          // Links
          linkTargetNewWindow: true,
          linkNoPrefix: true,
        }}
        onChange={(content: string) => onContentChange(content)}
        onImageUploadBefore={(
          files: File[],
          info: object,
          uploadHandler: UploadBeforeHandler
        ): UploadBeforeReturn => {
          if (!mediaEndpoint) {
            return undefined
          }

          try {
            if (files && files.length > 0) {
              // Upload select image
              uploadMedia({
                type: "image",
                url: mediaEndpoint,
                file: files[0],
              }).then((data) => {
                // If success, pass to uploadHandler
                if (data?.id) {
                  const result = {
                    result: [
                      {
                        url: data?.file,
                        name: files[0].name,
                        size: files[0].size,
                      },
                    ],
                  }

                  uploadHandler(result)
                }
              })
            } else {
              return false
            }
          } catch (err: any) {
            captureException(err)

            uploadHandler(err.toString())
            return false
          }

          // Will wait for uploadHandler to execute
          return undefined
        }}
        {...props}
      />
    </div>
  )
}
export default RichTextEditor
