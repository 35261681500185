import clsx from "clsx"
import { FC } from "react"
import { LinkText } from "@/text"
import InputEl from "./InputEl"
import { RadioCheckboxElProps } from "./types"

const RadioCheckboxEl: FC<RadioCheckboxElProps> = ({
  id,
  type,
  label,
  htmlLabel,
  className,
  validationText,
  theme,
  ...props
}) => (
  <div className={className}>
    <InputEl
      id={id}
      aria-label={label}
      type={type}
      theme={theme}
      className={clsx(
        "mr-2 cursor-pointer appearance-none border-2 bg-transparent !p-1.5",
        theme === "light" && "border-grey-0 checked:bg-grey-0",
        theme === "dark" && "border-grey-900 checked:bg-grey-900",
        type === "radio" && "rounded-full"
      )}
      {...props}
    />
    <LinkText as="label" htmlFor={id} className="cursor-pointer">
      {htmlLabel || label}
    </LinkText>
    {validationText && <div className="pt-1">{validationText}</div>}
  </div>
)

export default RadioCheckboxEl
