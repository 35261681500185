import { Body1, Body2 } from "@/text"
import createStyle, { CustomTagArgs } from "@/utils/createStyle"
import { FC } from "react"

export const inputThemes = {
  light:
    "bg-grey-0 text-grey-900 border border-grey-500 focus-visible:outline focus-visible:outline-grey-900 hover:outline hover:outline-1 hover:outline-grey-900 rounded-[1px] disabled:hover:outline-none disabled:text-grey-600 disabled:focus-visible:outline-none",
  dark: "bg-grey-900 text-grey-0 border border-grey-800 focus-visible:outline focus-visible:outline-grey-0 hover:outline hover:outline-1 hover:outline-grey-0 rounded-[1px]",
}

export const Labels: Record<
  string,
  FC<{ as?: keyof JSX.IntrinsicElements } & CustomTagArgs<HTMLLabelElement>>
> = {
  light: (props) => <Body1 as="label" className="mb-2 block" {...props} />,
  dark: (props) => <Body2 as="label" className="mb-2 block" {...props} />,
}

export const InputWrapper = createStyle("div", "group relative")
